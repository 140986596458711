import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FormSelect from "../../forms/FormSelect";
import FormInput from "../../forms/FormInput";
import { useFormik } from "formik";
import URLS from "../../services/urlConstants";
import { get } from "../../services/apiMethods";
import BundleNewUsedAndCombination from "./BundleNewUsedAndCombination";
import ManualBundling from "./ManualBundling";
import { getRandomSubset, hasAllValues } from "../../utils/utilities";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import { Close, Remove } from "@mui/icons-material";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import DashBoardPageHeaderPage from "../../components/DashBoard/DashBoardHeaderPage";
import WarehouseContext from "../../context/WarehouseContext";
import CustomSelectVirtualized from "../../components/SecondaryForms/CustomSelectVirtualized";

const createUniqueBundles = (items, combinationData) => {
  console.log(combinationData, "combinationData");
  let bundles = [];

  let remainingItems = [...items];

  for (let item of remainingItems) {
    let bundle = [];

    for (let combo of combinationData) {
      let requiredItems = remainingItems.filter(
        (i) => i.subFamily._id == combo.subFamily._id
      );
      if (requiredItems.length && requiredItems.length >= combo.count) {
        bundle = [...bundle, ...requiredItems.slice(0, combo.count)];
        remainingItems = remainingItems.filter(
          (i) => ![...bundle.map((i) => i._id)].includes(i._id)
        );
      }
    }

    if (
      bundle.length ==
      combinationData.reduce((final, current) => final + current.count, 0)
    ) {
      bundles.push(bundle);
    }
  }

  // formik.setFieldValue("quantity", bundles.length);

  return bundles;
};

export default function Add() {
  const { selectedWarehouse, selectedOwnerCode } = useContext(WarehouseContext);
  const theme = useTheme();
  let fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [bundleType, setBundleType] = useState([]);
  const [items, setItems] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [bundleData, setBundleData] = useState([]);

  const [selectedMsn, setSelectedMsn] = useState([]);
  const [manualItems, setManualItems] = useState([]);
  const [openItemSelectDialog, setOpenItemSelectDialog] = useState(false);

  const [inStockItems, setInStockItems] = useState([]);

  const handleCloseItemSelect = () => {
    setManualItems([]);
    setOpenItemSelectDialog(false);
  };

  const getAllItem = async () => {
    try {
      const { data } = await get(URLS.items.list, {
        params: {
          search: { inStock: true, inBundle: false, isSaved: false },
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      let result = data.result;
      setItems(result);
      setInStockItems(
        result.map((i) => ({
          ...i,
          label: `${i.msn} -- ${i.stockType.name} -- ${i.subFamily.name}`,
          value: i.msn,
        }))
      );

      let itemData = [];

      for (let item of result) {
        if (itemData.find((id) => id.subFamily == item.subFamily.name)) {
          let previousData = itemData.find(
            (id) => id.subFamily == item.subFamily.name
          );
          if (item.itemCategory?.name?.toLowerCase() === "new") {
            previousData.count.new += 1;
          } else if (item.itemCategory?.name?.toLowerCase() === "used") {
            previousData.count.used += 1;
          }

          itemData = itemData.map((i) =>
            i.subFamily === previousData.subFamily ? previousData : i
          );
        } else {
          let newItem = {
            subFamily: item.subFamily.name,
            count: {
              new: item.itemCategory?.name?.toLowerCase() === "new" ? 1 : 0,
              used: item.itemCategory?.name?.toLowerCase() === "used" ? 1 : 0,
            },
          };
          itemData.push(newItem);
        }
      }

      let mappedData = [];
      let row1 = ["Assets & Categories"];
      let row2 = ["New"];
      let row3 = ["Used"];
      let row4 = ["Total"];
      for (let data of itemData) {
        row1 = [...row1, data.subFamily];
        row2 = [...row2, data.count.new];
        row3 = [...row3, data.count.used];
        row4 = [...row4, data.count.new + data.count.used];
      }

      mappedData = [row1, row2, row3, row4];

      setItemData(mappedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllItem();
  }, [selectedWarehouse, selectedOwnerCode]);

  const categoryType = [
    { label: "New Only", value: "new" },
    {
      label: "Used Only",
      value: "used",
    },
    {
      label: "Combination",
      value: "combination",
    },
    {
      label: "Manual",
      value: "manual",
    },
  ];

  const combineItems = (values) => {
    if (!hasAllValues(values)) return;
    let filteredBundleData = [];

    if (
      items.length &&
      ["new", "used", "combination"].includes(values.category)
    ) {
      if (values.category) {
        if (values.category === "new") {
          filteredBundleData = items.filter(
            (i) => i.itemCategory.name.toLowerCase() === "new"
          );
        } else if (values.category === "used") {
          filteredBundleData = items.filter(
            (i) => i.itemCategory.name.toLowerCase() === "used"
          );
        } else if (values.category === "combination") {
          filteredBundleData = [...items];
        } else if (values.category === "manual") {
          filteredBundleData = [];
        }
      }

      if (values.bundleCode) {
        let bundleInfo = bundleType.find((b) => b._id == values.bundleCode);

        let subFamiliesId = bundleInfo.bundleData.map((b) => b.subFamily._id);

        filteredBundleData = [
          ...filteredBundleData.filter((i) =>
            subFamiliesId.find((s) => s === i.subFamily._id)
          ),
        ];

        const finalBundleData = createUniqueBundles(
          filteredBundleData,
          bundleInfo.bundleData,
          formik
        );

        const finalList = getRandomSubset(finalBundleData, values.quantity);
        if (!finalList.length)
          return toast.info(
            "Not enough items to create bundles for the selected bundle code!"
          );

        if (values.quantity > finalBundleData.length) {
          toast.info(
            `Maximum of ${finalBundleData.length} bundles is available to create!`
          );
        }

        setBundleData(getRandomSubset(finalBundleData, values.quantity));
      }
    } else {
      toast.info("No items to create bundle");
    }
  };

  const formik = useFormik({
    initialValues: {
      category: "",
      quantity: "",
      bundleCode: "",
    },
    onSubmit: combineItems,
  });

  const handleShowBundleInfoClick = () => {
    let bundleInfo = bundleType.find((b) => b._id == formik.values.bundleCode);

    let itemsInMultiple = bundleInfo.bundleData.reduce(
      (final, current) => final + current.count,
      0
    );

    if (manualItems.length % itemsInMultiple !== 0) {
      return toast.info("Not enough items to create required bundle!");
    }
    const bundles = createUniqueBundles(manualItems, bundleInfo.bundleData);
    if (!bundles.length)
      return toast.info(
        "Not enough items to create bundles for the selected bundle code!"
      );
    setBundleData(bundles);
    setOpenItemSelectDialog(false);
  };

  useEffect(() => {
    if (bundleData.length) {
      setBundleData([]);
    }
  }, [formik.values, selectedWarehouse]);

  const getBundleType = async () => {
    try {
      const { data } = await get(URLS.bundleType.list);
      setBundleType(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBundleType();
  }, []);

  const handleOnBundleCreate = () => {
    formik.resetForm();
    setManualItems([]);
    setSelectedMsn([]);
    getAllItem();
  };

  const handleChangeSelectItem = (e) => {
    setSelectedMsn(e.target.value);
    let itemsWithGivenMSN = items.filter((i) => e.target.value.includes(i.msn));
    setManualItems(itemsWithGivenMSN);
  };

  const handleManualItemRemove = (id) => {
    setSelectedMsn(
      selectedMsn.filter((m) => m !== manualItems.find((i) => i._id == id).msn)
    );
    setManualItems(manualItems.filter((i) => i._id !== id));
  };

  return (
    <>
      <DashBoardPageHeaderPage title="Add Bundle" />
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {itemData.map((value, index) => (
              <TableRow key={index}>
                {value.map((v, i) => (
                  <TableCell
                    key={i}
                    sx={{
                      width: i === 0 ? "6%" : "10%",
                      color: i === 0 ? "#fff" : "inherit",
                      backgroundColor:
                        i === 0
                          ? (theme) => theme.palette.primary.main
                          : "inherit",
                    }}
                    align={i === 0 ? "center" : "center"}
                  >
                    {v}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container spacing={2} sx={{ margin: "15px 0px" }}>
        <Grid item xs={12} md={6} lg={3}>
          <FormSelect
            required={true}
            name="category"
            formik={formik}
            label="Select Category"
            options={categoryType}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <FormSelect
            required={true}
            name="bundleCode"
            formik={formik}
            label="Select Bundle Type"
            options={bundleType.map((b) => ({
              label: `${b.code} (${b.description})`,
              value: b._id,
            }))}
          />
        </Grid>
        {formik.values.category !== "manual" && (
          <Grid item xs={12} md={6} lg={3}>
            <FormInput
              required={true}
              name="quantity"
              formik={formik}
              label="Number of bundles"
            />
          </Grid>
        )}

        <Grid
          sx={{ display: "flex", alignItems: "center" }}
          item
          xs={12}
          md={6}
          lg={3}
        >
          <Button
            variant="contained"
            size="small"
            onClick={(e) =>
              formik.values.category === "manual"
                ? !hasAllValues(formik.values, "quantity")
                  ? null
                  : setOpenItemSelectDialog(true)
                : formik.handleSubmit(e)
            }
          >
            {formik.values.category === "manual" ? "Select Items" : "Find"}
          </Button>
        </Grid>
      </Grid>

      {bundleData.length ? (
        <BundleNewUsedAndCombination
          key={selectedWarehouse}
          onBundleCreate={handleOnBundleCreate}
          bundleType={formik.values.bundleCode}
          bundles={bundleData}
        />
      ) : null}

      <Dialog
        fullScreen={fullScreen}
        open={openItemSelectDialog}
        onClose={handleCloseItemSelect}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: 650,
          },
        }}
      >
        <DialogTitle>Add Items to create bundles</DialogTitle>
        <Divider />
        <DialogContent
          sx={{
            padding: "10px",
          }}
        >
          <Box sx={{ mb: "20px" }}>
            <CustomSelectVirtualized
              name="selectItem"
              label="MSN"
              multiple={true}
              value={selectedMsn}
              required={true}
              options={inStockItems.filter((i) =>
                bundleType
                  .find((b) => b._id === formik.values.bundleCode)
                  ?.bundleData.map((b) => b.subFamily._id)
                  .includes(i.subFamily._id)
              )}
              onChange={handleChangeSelectItem}
            />
          </Box>
          {!manualItems.length
            ? null
            : manualItems.map((i, index) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "5px 10px",
                  }}
                  key={i._id}
                >
                  <Typography>
                    {i.msn} -- {i.stockType.name} -- {i.subFamily.name}
                  </Typography>{" "}
                  <IconButton
                    size="small"
                    onClick={(e) => handleManualItemRemove(i._id)}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </Box>
              ))}
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={handleCloseItemSelect}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={handleShowBundleInfoClick}
          >
            Show bundle info
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
