import {
  FormControl,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
  Box,
  ClickAwayListener,
  Portal,
  Typography,
  Checkbox,
  ListItemText,
} from "@mui/material";
import ThemeModeContext from "../../context/ThemeModeContext";
import React, {
  memo,
  useContext,
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import SearchIcon from "@mui/icons-material/Search";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

const containsText = (text, searchText) =>
  text?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;

function CustomSelectVirtualized({
  value = [],
  name = "",
  label = "",
  options = [],
  onChange = () => {},
  disabled = false,
  required = false,
  multiple = false,
}) {
  const [blurred, setBlurred] = useState(false);
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);
  const { isDarkMode } = useContext(ThemeModeContext);
  const [searchText, setSearchText] = useState("");
  const [dropdownPosition, setDropdownPosition] = useState(null);

  const displayedOptions = useMemo(
    () => options.filter((option) => containsText(option.label, searchText)),
    [searchText, options]
  );

  useEffect(() => {
    setSearchText("");
  }, [blurred]);

  const labelColor = disabled ? "gray" : isDarkMode ? "white" : "black";
  const backgroundColor = isDarkMode ? "#333" : "white";
  const textColor = isDarkMode ? "white" : "black";
  const borderColor = isDarkMode
    ? "rgba(255, 255, 255, 0.23)"
    : "rgba(0, 0, 0, 0.23)";

  const handleOptionToggle = (selectedValue) => {
    let newValue;
    if (multiple) {
      newValue = value.includes(selectedValue)
        ? value.filter((val) => val !== selectedValue)
        : [...value, selectedValue];
    } else {
      newValue = selectedValue;
      setOpen(false);
    }
    onChange({ target: { name, value: newValue } });
  };

  const Row = useCallback(
    ({ index, style }) => {
      const option = displayedOptions[index];
      const isSelected = multiple
        ? value.includes(option.value)
        : value === option.value;

      return (
        <MenuItem
          key={index}
          value={option.value}
          style={{ ...style, color: textColor, backgroundColor }}
          onMouseDown={() => handleOptionToggle(option.value)} // Change to onMouseDown
        >
          {multiple && (
            <Checkbox
              checked={isSelected}
              color="primary"
              style={{ marginRight: 8 }}
            />
          )}
          <ListItemText primary={option.label} />
        </MenuItem>
      );
    },
    [
      displayedOptions,
      handleOptionToggle,
      value,
      multiple,
      textColor,
      backgroundColor,
    ]
  );

  const handleOpen = () => {
    const rect = inputRef.current.getBoundingClientRect();
    setDropdownPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
      width: rect.width,
    });
    setOpen(true);
  };

  const selectedLabels = useMemo(
    () =>
      multiple
        ? options
            .filter((opt) => value.includes(opt.value))
            .map((opt) => opt.label)
            .join(", ")
        : options.find((opt) => opt.value === value)?.label ||
          "Select an option",
    [multiple, options, value]
  );

  return (
    <FormControl
      margin="normal"
      required={required}
      variant="outlined"
      size="small"
      fullWidth
      sx={{ top: "4px" }}
    >
      <Box
        position="absolute"
        top={-12}
        left={8}
        px={0.5}
        bgcolor={backgroundColor}
        zIndex={2}
        style={{
          transform: "translateY(-10%)",
          pointerEvents: "none",
        }}
      >
        <Typography
          sx={{ fontSize: "10px" }}
          variant="caption"
          style={{ color: labelColor }}
        >
          {label}
          {required ? "*" : null}
        </Typography>
      </Box>

      <Box
        ref={inputRef}
        onClick={handleOpen}
        sx={{
          border: `1px solid ${borderColor}`,
          borderRadius: "4px",
          padding: "8px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: disabled ? "#f5f5f5" : backgroundColor,
          color: textColor,
        }}
      >
        {selectedLabels}
      </Box>

      {open && dropdownPosition && (
        <Portal>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Box
              position="absolute"
              top={dropdownPosition.top}
              left={dropdownPosition.left}
              width={dropdownPosition.width}
              zIndex={1300}
              bgcolor={backgroundColor}
              boxShadow={3}
              borderRadius="4px"
              mt={1}
            >
              <ListSubheader>
                <TextField
                  autoFocus
                  size="small"
                  placeholder="Type to search..."
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: textColor }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ input: { color: textColor, backgroundColor } }}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      setOpen(false);
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              <Box height={200} width="100%">
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      height={height}
                      itemCount={displayedOptions.length}
                      itemSize={() => 48}
                      width={width}
                    >
                      {Row}
                    </List>
                  )}
                </AutoSizer>
              </Box>
            </Box>
          </ClickAwayListener>
        </Portal>
      )}
    </FormControl>
  );
}

export default memo(CustomSelectVirtualized);
