import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  FormControl,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
  Box,
  ClickAwayListener,
  Portal,
  Typography,
} from "@mui/material";
import ThemeModeContext from "../context/ThemeModeContext";
import SearchIcon from "@mui/icons-material/Search";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

const containsText = (text, searchText) =>
  text?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;

const FormSelectVirtualized = ({
  label,
  name,
  options,
  formik,
  disabled = false,
  multiple = false,
  required = false,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const inputRef = useRef(null);
  const { isDarkMode } = useContext(ThemeModeContext);
  const [dropdownPosition, setDropdownPosition] = useState(null);

  const labelColor = disabled ? "gray" : isDarkMode ? "white" : "black";
  const backgroundColor = isDarkMode ? "#333" : "white";
  const textColor = isDarkMode ? "white" : "black";
  const borderColor = isDarkMode
    ? "rgba(255, 255, 255, 0.23)"
    : "rgba(0, 0, 0, 0.23)";

  const displayedOptions = useMemo(
    () => options.filter((option) => containsText(option.label, searchText)),
    [searchText, options]
  );

  const handleOpen = () => {
    const rect = inputRef.current.getBoundingClientRect();
    setDropdownPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
      width: rect.width,
    });
    setOpen(true);
  };

  const handleOptionToggle = (selectedValue) => {
    let newValue;
    if (multiple) {
      newValue = formik.values[name].includes(selectedValue)
        ? formik.values[name].filter((val) => val !== selectedValue)
        : [...formik.values[name], selectedValue];
    } else {
      newValue = selectedValue;
      setOpen(false);
    }
    formik.setFieldValue(name, newValue);
  };

  const Row = useCallback(
    ({ index, style }) => {
      const option = displayedOptions[index];
      const isSelected = multiple
        ? formik.values[name]?.includes(option.value)
        : formik.values[name] === option.value;

      return (
        <MenuItem
          key={index}
          value={option.value}
          style={{
            ...style,
            color: isDarkMode ? "white" : "black",
            backgroundColor: isDarkMode ? "#333" : "white",
          }}
          onMouseDown={() => handleOptionToggle(option.value)}
        >
          {option.label}
        </MenuItem>
      );
    },
    [displayedOptions, handleOptionToggle, formik.values, multiple, isDarkMode]
  );

  return (
    <FormControl
      margin="normal"
      required={required}
      variant="outlined"
      size="small"
      fullWidth
    >
      <Box
        position="absolute"
        top={-12}
        left={8}
        px={0.5}
        bgcolor={backgroundColor}
        zIndex={2}
        style={{
          transform: "translateY(-10%)",
          pointerEvents: "none",
        }}
      >
        <Typography
          sx={{ fontSize: "10px" }}
          variant="caption"
          style={{ color: labelColor }}
        >
          {label}
          {required ? "*" : null}
        </Typography>
      </Box>
      <Box
        ref={inputRef}
        onClick={handleOpen}
        sx={{
          border: `1px solid ${
            isDarkMode ? "rgba(255, 255, 255, 0.23)" : "rgba(0, 0, 0, 0.23)"
          }`,
          borderRadius: "4px",
          padding: "8px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: disabled ? "#f5f5f5" : isDarkMode ? "#333" : "white",
          color: isDarkMode ? "white" : "black",
        }}
      >
        {formik.values[name] || "Select an option"}
      </Box>

      {open && dropdownPosition && (
        <Portal>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Box
              position="absolute"
              top={dropdownPosition.top}
              left={dropdownPosition.left}
              width={dropdownPosition.width}
              zIndex={1300}
              bgcolor={isDarkMode ? "#333" : "white"}
              boxShadow={3}
              borderRadius="4px"
              mt={1}
            >
              <ListSubheader>
                <TextField
                  autoFocus
                  size="small"
                  placeholder="Type to search..."
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{ color: isDarkMode ? "white" : "black" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      setOpen(false);
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              <Box height={200} width="100%">
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      height={height}
                      itemCount={displayedOptions.length}
                      itemSize={() => 48}
                      width={width}
                    >
                      {Row}
                    </List>
                  )}
                </AutoSizer>
              </Box>
            </Box>
          </ClickAwayListener>
        </Portal>
      )}
    </FormControl>
  );
};

export default FormSelectVirtualized;
