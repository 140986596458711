import React, { useContext, useEffect, useState } from "react";
import NewStock from "../components/Inventory/NewStock";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import UsedStock from "../components/Inventory/UsedStock";
import FaultyStock from "../components/Inventory/FaultyStock";
import SavedStock from "../components/Inventory/SavedStock";
import BundledStock from "../components/Inventory/BundledStock";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

// services
import URLS from "../services/urlConstants";
import { get } from "../services/apiMethods";
import { downloadExcel } from "../utils/utilities";
import DashBoardPageHeaderPage from "../components/DashBoard/DashBoardHeaderPage";
import WarehouseContext from "../context/WarehouseContext";
import ShippedStock from "../components/Inventory/ShippedStock";

export default function Inventory() {
  const { selectedWarehouse, selectedOwnerCode } = useContext(WarehouseContext);
  const [newStock, setNewStock] = useState({});
  const [usedStock, setUsedStock] = useState({});
  const [faultyStock, setFaultyStock] = useState({});
  const [savedStock, setSavedStock] = useState({});
  const [bundles, setBundles] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [subFamily, setSubFamily] = useState([]);
  const [shippedStock, setShippedStock] = useState([]);
  const [bundleTypes, setBundleTypes] = useState([]);

  const getAllBundleTypes = async () => {
    try {
      const { data } = await get(URLS.bundleType.list);
      setBundleTypes(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBundleTypes();
  }, []);

  const getSubFamily = async () => {
    try {
      const { data } = await get(URLS.subFamily.list);
      setSubFamily(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const getBundles = async () => {
    try {
      const { data } = await get(URLS.bundle.list, {
        params: {
          search: { inStock: true },
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      let newBundles = [];
      for (let bundle of data.result) {
        if (newBundles.find((b) => b.name === bundle.bundleType.code)) {
          newBundles = newBundles.map((b) =>
            b._id === bundle.bundleType._id
              ? { ...b, count: b.count + bundle.items.length }
              : b
          );
        } else {
          let newData = {
            _id: bundle.bundleType._id,
            name: bundle.bundleType.code,
            description: bundle.bundleType.description,
            count: bundle.items.length,
          };
          newBundles.push(newData);
        }
      }
      setBundles(newBundles);
    } catch (error) {
      console.log(error);
    }
  };

  const getFaultyItemsList = async () => {
    try {
      const { data } = await get(URLS.items.list, {
        params: {
          search: { inStock: false, inBundle: false },
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });

      let faultyItemData = {};
      faultyItemData["Assets"] = [];
      faultyItemData["Accessories"] = [];

      for (let item of data.result.filter(
        (i) => i.itemCategory?.name?.toLowerCase() === "faulty"
      )) {
        if (
          faultyItemData[item.subFamily.category].find(
            (i) => i.subFamilyId === item.subFamily._id
          )
        ) {
          let previousData = faultyItemData[item.subFamily.category].find(
            (id) => id.subFamilyId == item.subFamily._id
          );
          if (item.itemCategory?.name?.toLowerCase() === "faulty") {
            previousData.count += 1;
          } else {
          }

          faultyItemData[item.subFamily.category] = faultyItemData[
            item.subFamily.category
          ].map((i) =>
            i.subFamilyId === previousData.subFamilyId ? previousData : i
          );
        } else {
          let newEntry = {
            subFamilyId: item.subFamily._id,
            subFamilyName: item.subFamily.name,
            count: item.itemCategory.name.toLowerCase() === "faulty" ? 1 : 0,
          };

          faultyItemData[item.subFamily.category].push(newEntry);
        }
      }
      setFaultyStock(faultyItemData);
    } catch (error) {}
  };

  const getItemsList = async () => {
    try {
      const { data } = await get(URLS.items.list, {
        params: {
          search: { inStock: true, inBundle: false },
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      let newItemData = {};
      newItemData["Assets"] = [];
      newItemData["Accessories"] = [];
      newItemData["Spares"] = [];

      for (let item of data.result) {
        if (
          newItemData[item.subFamily.category].find(
            (i) => i.subFamilyId === item.subFamily._id
          )
        ) {
          let previousData = newItemData[item.subFamily.category].find(
            (id) => id.subFamilyId == item.subFamily._id
          );
          if (item.itemCategory?.name?.toLowerCase() === "new") {
            previousData.count += 1;
          } else {
          }

          newItemData[item.subFamily.category] = newItemData[
            item.subFamily.category
          ].map((i) =>
            i.subFamilyId === previousData.subFamilyId ? previousData : i
          );
        } else {
          let newEntry = {
            subFamilyId: item.subFamily._id,
            subFamilyName: item.subFamily.name,
            count: item.itemCategory.name.toLowerCase() === "new" ? 1 : 0,
          };

          newItemData[item.subFamily.category].push(newEntry);
        }
      }

      setNewStock(newItemData);

      let usedItemData = {};
      usedItemData["Assets"] = [];
      usedItemData["Accessories"] = [];
      usedItemData["Spares"] = [];

      for (let item of data.result) {
        if (
          usedItemData[item.subFamily.category].find(
            (i) => i.subFamilyId === item.subFamily._id
          )
        ) {
          let previousData = usedItemData[item.subFamily.category].find(
            (id) => id.subFamilyId == item.subFamily._id
          );
          if (item.itemCategory?.name?.toLowerCase() === "used") {
            previousData.count += 1;
          } else {
          }

          usedItemData[item.subFamily.category] = usedItemData[
            item.subFamily.category
          ].map((i) =>
            i.subFamilyId === previousData.subFamilyId ? previousData : i
          );
        } else {
          let newEntry = {
            subFamilyId: item.subFamily._id,
            subFamilyName: item.subFamily.name,
            count: item.itemCategory.name.toLowerCase() === "used" ? 1 : 0,
          };

          usedItemData[item.subFamily.category].push(newEntry);
        }
      }

      setUsedStock(usedItemData);
    } catch (error) {}
  };

  const getSavedStock = async () => {
    try {
      const { data } = await get(URLS.items.listSavedStock, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      let items = data.result;

      let savedItemData = {};
      savedItemData["Assets"] = [];
      savedItemData["Accessories"] = [];
      savedItemData["Spares"] = [];

      for (let item of items) {
        if (
          savedItemData[
            subFamily.find((s) => s._id == item.subFamily._id)?.category
          ].find((i) => i.subFamilyId === item.subFamily._id)
        ) {
          let previousData = savedItemData[
            subFamily.find((s) => s._id == item.subFamily._id)?.category
          ].find((id) => id.subFamilyId == item.subFamily._id);
          previousData.count += 1;

          savedItemData[
            subFamily.find((s) => s._id == item.subFamily._id)?.category
          ] = savedItemData[
            subFamily.find((s) => s._id == item.subFamily._id)?.category
          ].map((i) =>
            i.subFamilyId === previousData.subFamilyId ? previousData : i
          );
        } else {
          let newEntry = {
            subFamilyId: item.subFamily._id,
            subFamilyName: subFamily.find((s) => s._id == item.subFamily._id)
              ?.name,
            count: 1,
          };

          savedItemData[
            subFamily.find((s) => s._id == item.subFamily._id)?.category
          ].push(newEntry);
        }
      }
      setSavedStock(savedItemData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (subFamily.length) {
      getSavedStock();
    }
  }, [subFamily.length, selectedWarehouse, selectedOwnerCode]);

  useEffect(() => {
    getItemsList();
    getBundles();
    getSubFamily();
    getFaultyItemsList();
  }, [selectedWarehouse, selectedOwnerCode]);

  const downloadAllItems = async () => {
    try {
      const { data } = await get(URLS.items.downloadTotalStock, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
        responseType: "blob",
      });
      downloadExcel(data, "Total_Inventory");
    } catch (error) {
      console.log(error);
    }
  };

  const getAllOutboundEntries = async () => {
    try {
      const { data } = await get(URLS.outbound.list, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });

      let entries = data.result;

      let shippedItemData = {};
      shippedItemData["Assets"] = [];
      shippedItemData["Accessories"] = [];
      shippedItemData["Bundles"] = [];
      shippedItemData["Spares"] = [];

      if (entries && entries.length) {
        for (let outbound of entries) {
          for (let item of outbound.items) {
            if (item.individualItem) {
              if (
                shippedItemData[item.individualItem.subFamily.category].find(
                  (i) => i.subFamilyId === item.individualItem.subFamily._id
                )
              ) {
                let previousData = shippedItemData[
                  item.individualItem.subFamily.category
                ].find(
                  (id) => id.subFamilyId == item.individualItem.subFamily._id
                );
                previousData.count += 1;

                shippedItemData[item.individualItem.subFamily.category] =
                  shippedItemData[item.individualItem.subFamily.category].map(
                    (i) =>
                      i.subFamilyId === previousData.subFamilyId
                        ? previousData
                        : i
                  );
              } else {
                let newEntry = {
                  subFamilyId: item.individualItem.subFamily._id,
                  subFamilyName: item.individualItem.subFamily.name,
                  count: 1,
                  entryId: outbound._id,
                  isBundle: false,
                };

                shippedItemData[item.individualItem.subFamily.category].push(
                  newEntry
                );
              }
            } else if (item.bundledItem) {
              if (
                shippedItemData["Bundles"].find(
                  (i) => i.subFamilyId === item.bundledItem.bundleType
                )
              ) {
                let previousData = shippedItemData["Bundles"].find(
                  (id) => id.subFamilyId == item.bundledItem.bundleType
                );
                previousData.count += item.bundledItem.items.length;

                shippedItemData["Bundles"] = shippedItemData["Bundles"].map(
                  (i) =>
                    i.subFamilyId === previousData.subFamilyId
                      ? previousData
                      : i
                );
              } else {
                let name = bundleTypes.find(
                  (b) => b._id === item.bundledItem.bundleType
                )?.code;

                let newEntry = {
                  subFamilyId: item.bundledItem.bundleType,
                  subFamilyName: name,
                  count: item.bundledItem.items.length,
                  entryId: outbound._id,
                  isBundle: true,
                };

                shippedItemData["Bundles"].push(newEntry);
              }
            }
          }
        }
      }

      setShippedStock(shippedItemData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllOutboundEntries();
  }, [selectedWarehouse, bundleTypes, selectedOwnerCode]);

  return (
    <div>
      <DashBoardPageHeaderPage title="Reports" />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid lightgray",
          borderRadius: "5px",
          margin: "20px 0",
          padding: "5px",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>Total Stock</Typography>

        <IconButton size="small" onClick={downloadAllItems}>
          <DownloadRoundedIcon fontSize="small" />
        </IconButton>
      </Box>
      <Grid sx={{ zoom: 0.9 }} spacing={2} container>
        <Grid item xs={12} sm={12}>
          <NewStock data={newStock} warehouseId={selectedWarehouse} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UsedStock data={usedStock} warehouseId={selectedWarehouse} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FaultyStock data={faultyStock} warehouseId={selectedWarehouse} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SavedStock data={savedStock} warehouseId={selectedWarehouse} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <BundledStock data={bundles} warehouseId={selectedWarehouse} />
        </Grid>

        <Grid item xs={12} sm={12}>
          <ShippedStock data={shippedStock} warehouseId={selectedWarehouse} />
        </Grid>
      </Grid>
    </div>
  );
}
