import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from "@mui/material";

import dayjs from "dayjs";
import FormInput from "../forms/FormInput";
import FormDatePicker from "../forms/FormDatePicker";
import CustomInput from "../components/SecondaryForms/CustomInput";
import { get, put } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import CustomSelect from "../components/SecondaryForms/CustomSelect";
import { LoadingButton } from "@mui/lab";
import { hasAllValues } from "../utils/utilities";
import ShowComponent from "../components/ShowComponent";
import WarehouseContext from "../context/WarehouseContext";
import DashBoardPageHeaderPage from "../components/DashBoard/DashBoardHeaderPage";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));

export default function Shipment() {
  const [formData, setFormData] = useState([]);
  const [updateStatuses, setUpdateStatuses] = useState([]);
  const [updating, setUpdating] = useState(-1);
  const [downloadingReport, setDownloadingReport] = useState(-1);
  const { selectedWarehouse, selectedOwnerCode } = useContext(WarehouseContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdateOutbound = async (e, form) => {
    e.preventDefault();
    try {
      if (!hasAllValues(form)) return;
      let payload = {
        itemId: form._id,
        shippingRemarks: form.shippingRemarks,
        updateStatus: form.updateStatus,
      };
      setUpdating(formData.indexOf(form));
      const { data } = await put(
        URLS.outbound.update + "/" + form.entryId,
        payload
      );
      let entry = data.result;
      setUpdating(-1);
      getAllEntries();
    } catch (error) {
      console.log(error);
      setUpdating(-1);
    }
  };

  const getUpdateStatuses = async () => {
    try {
      const { data } = await get(URLS.updateStatus.list);
      setUpdateStatuses(
        data.result.map((s) => ({ ...s, label: s.name, value: s._id }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    getUpdateStatuses();
  }, []);

  const handleFind = async (values) => {
    try {
      const { data } = await get(URLS.outbound.list, {
        params: {
          search: values,
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      let entries = data.result;
      if (entries && entries.length) {
        let forms = [];
        for (let entry of entries) {
          for (let item of entry.items) {
            let newForm = {
              entryId: entry._id,
              _id: item._id,
              courierPartner: item.courierPartner.name,
              courierMode: item.courierMode.name,
              awbNo: item.awbNo,
              pincode: item.pincode,
              updateStatus:
                item?.updateStatus[item.updateStatus.length - 1]?.status?._id ||
                "",
              shippingRemarks: item.shippingRemarks || "NA",
              date: new Date(entry.dispatchedAt).toLocaleDateString(),
              time: new Date(entry.dispatchedAt).toLocaleTimeString(),
            };
            forms.push(newForm);
          }
        }
        setFormData(forms);
      } else {
        setFormData([]);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getAllEntries = async () => {
    try {
      const { data } = await get(URLS.outbound.list, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      let entries = data.result;
      if (entries && entries.length) {
        let forms = [];
        for (let entry of entries) {
          for (let item of entry.items) {
            let newForm = {
              entryId: entry._id,
              _id: item._id,
              courierPartner: item.courierPartner?.name,
              courierMode: item.courierMode?.name,
              awbNo: item.awbNo,
              pincode: item.pincode,
              updateStatus:
                item?.updateStatus[item.updateStatus.length - 1]?.status?._id ||
                "",
              shippingRemarks: item.shippingRemarks || "NA",
              date: new Date(entry.dispatchedAt).toLocaleDateString(),
              time: new Date(entry.dispatchedAt).toLocaleTimeString(),
            };
            forms.push(newForm);
          }
        }
        setFormData(forms);
      } else {
        setFormData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllEntries();
  }, [selectedWarehouse, selectedOwnerCode]);

  const entryFormik = useFormik({
    initialValues: {
      name: "",
      fromDate: dayjs(new Date()),
      toDate: dayjs(new Date()),
    },
    onSubmit: handleFind,
  });

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setFormData(
      formData.map((fd, i) => (i === index ? { ...fd, [name]: value } : fd))
    );
  };

  const handleClearFilter = () => {
    entryFormik.resetForm();
    getAllEntries();
  };

  return (
    <>
      <DashBoardPageHeaderPage title="Shipments" />
      <Grid rowSpacing={1} columnSpacing={2} container>
        <Grid xs={12} sm={6} md={3} item>
          <FormInput
            type="text"
            formik={entryFormik}
            name="name"
            label="AWB No"
            required={true}
          />
        </Grid>

        <Grid xs={12} sm={6} md={3} item>
          <FormDatePicker
            formik={entryFormik}
            label="From Date"
            name="fromDate"
          />
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <FormDatePicker formik={entryFormik} label="To Date" name="toDate" />
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <Stack columnGap={1} direction="row">
            <Button
              size="small"
              onClick={entryFormik.handleSubmit}
              variant="contained"
              sx={{ mt: 2 }}
            >
              Filter
            </Button>
            {entryFormik.values.name ? (
              <Button
                size="small"
                onClick={handleClearFilter}
                variant="outlined"
                sx={{ mt: 2 }}
              >
                Clear Filter
              </Button>
            ) : null}
          </Stack>
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light,
            }}
          >
            <TableRow>
              <TableCell align="center" sx={{ minWidth: "80px" }}>
                S.No
              </TableCell>
              <StyledTableCell align="center">
                Courier Partner Name
              </StyledTableCell>
              <StyledTableCell align="center">Courier Mode</StyledTableCell>
              <StyledTableCell align="center">Courier AWB No.</StyledTableCell>
              <StyledTableCell align="center">Pincode</StyledTableCell>
              <StyledTableCell align="center">Update Status</StyledTableCell>
              <StyledTableCell align="center">Shipping Remarks</StyledTableCell>
              <StyledTableCell align="center">Date</StyledTableCell>
              <StyledTableCell align="center">Time</StyledTableCell>

              <TableCell align="center" sx={{ minWidth: "80px" }}>
                Action
              </TableCell>
              {/* <TableCell align="center" sx={{ minWidth: "80px" }}>
                Report
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {formData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((fd, i) => (
                <TableRow key={i}>
                  <TableCell align="center" sx={{ minWidth: "80px" }}>
                    {1 + i}
                  </TableCell>
                  <StyledTableCell align="center">
                    <Typography>{fd.courierPartner}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography>{fd.courierMode}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography>{fd.awbNo}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography>{fd.pincode}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      name="updateStatus"
                      value={fd.updateStatus}
                      label="Update Status"
                      required={true}
                      options={updateStatuses}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      name="shippingRemarks"
                      value={fd.shippingRemarks}
                      label="Shipping Remarks"
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography>{fd.date}</Typography>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Typography>{fd.time}</Typography>
                  </StyledTableCell>

                  <TableCell align="center" sx={{ minWidth: "80px" }}>
                    <ShowComponent module={"Shipment"} action={"update"}>
                      {" "}
                      <LoadingButton
                        size="small"
                        loading={updating === i}
                        onClick={(e) => handleUpdateOutbound(e, fd)}
                        variant="contained"
                        sx={{ mt: 1 }}
                      >
                        Save
                      </LoadingButton>
                    </ShowComponent>
                  </TableCell>
                  {/* <TableCell align="center" sx={{ minWidth: "80px" }}>
                  <ShowComponent module={"Shipment"} action={"view"}>
                    {" "}
                    <LoadingButton
                      size="small"
                      loading={downloadingReport === i}
                      onClick={(e) => handleDownloadShippingReport(e, fd)}
                      variant="contained"
                      sx={{ mt: 1 }}
                    >
                      Download
                    </LoadingButton>
                  </ShowComponent>
                </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        fullWidth
        component="div"
        count={formData ? (formData.length ? formData.length : 0) : 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 20]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
