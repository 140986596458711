import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useFormik } from "formik";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Stack,
  Button,
  TablePagination,
} from "@mui/material";
import PageHeader from "../../components/PageHeader";
import { hasAllValues, multiplyStringNumbers } from "../../utils/utilities";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import URLS from "../../services/urlConstants";
import { get, post, put } from "../../services/apiMethods";
import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import ShowComponent from "../../components/ShowComponent";
import FormSelect from "../../forms/FormSelect";
import { toast } from "react-toastify";
import TabList from "../../components/Tabs/TabList";
import TabPanel from "../../components/Tabs/TabPanel";
import FormInput from "../../forms/FormInput";
import FormDatePicker from "../../forms/FormDatePicker";
import WarehouseContext from "../../context/WarehouseContext";
import DashBoardPageHeaderPage from "../../components/DashBoard/DashBoardHeaderPage";
import { debounce } from "lodash";
import FormSelectVirtualized from "../../forms/FormSelectVirtualized";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "170px",
}));

export default function Outbound() {
  const [formData, setFormData] = useState([]);
  const { selectedWarehouse, selectedOwnerCode } = useContext(WarehouseContext);

  const [stockTypes, setStockTypes] = useState([]);
  const [subFamilies, setSubFamilies] = useState([]);
  const [packagingData, setPackagingData] = useState([]);
  const [courierData, setCourierData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [category, setCategory] = useState([]);
  const [courierPartner, setCourierPartner] = useState([]);
  const [pickLists, setPickLists] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [inStockItems, setInStockItems] = useState([]);
  const [entryToEdit, setEntryToEdit] = useState("");
  const [outbounds, setOutbounds] = useState([]);
  const [selectedPickLists, setSelectedPickLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [value, setSelectValue] = useState(0);
  const handleTabChange = (e, newValue) => setSelectValue(newValue);

  // custom fields
  const [formFields, setFormFields] = useState(null);

  //customform
  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: "outbound" },
      });
      setFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setFormData(formData.map((fd) => ({ ...fd, meta: formFields || [] })));
  }, [formFields]);

  useEffect(() => {
    getFormDetails();
  }, []);

  const getItems = async () => {
    try {
      const { data } = await get(URLS.items.list, {
        params: {
          search: { inStock: true, inBundle: false, isSaved: false },
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      setInStockItems(data.result.map((i) => ({ label: i.msn, value: i.msn })));
    } catch (error) {
      console.log(error);
    }
  };

  const getAllPickList = async () => {
    try {
      const { data } = await get(URLS.pickList.list, {
        params: {
          search: { used: false },
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });

      setPickLists(
        data.result.map((p) => ({
          label: p.gmsPickListCode,
          value: p.gmsPickListCode,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getBundles = async () => {
    try {
      const { data } = await get(URLS.bundle.list, {
        params: {
          search: { inStock: true },
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      setBundles(
        data.result?.map((d) => ({
          label: d.bundleReferenceNumber,
          value: d.bundleReferenceNumber,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedWarehouse !== "all") {
      getAllPickList();
      getBundles();
      getItems();
      setFormData([]);
      setSelectedPickLists([]);
    } else {
      // toast.error("Invalid Selection!");
    }
  }, [selectedWarehouse, selectedOwnerCode]);

  const refreshList = () => {
    getAllPickList();
    getBundles();
    getItems();
    setFormData([]);
    setSelectedPickLists([]);
  };

  const formik = useFormik({
    initialValues: {
      gmsPickListcode: "",
      manualBundleReferenceNumber: "",
      manualMsn: "",
    },
    onSubmit: console.log,
    validateOnBlur: false,
  });

  const getData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list);
      const subFamilyPromise = get(URLS.subFamily.list);
      const PackagingPromise = get(URLS.packaging.list);
      const courierModePromise = get(URLS.courierMode.list);
      const brandPromise = get(URLS.brand.list);
      const itemCategoryPromise = get(URLS.itemCategory.list);
      const courierPartnerPromise = get(URLS.courierPartner.list);

      const [
        stockTypeData,
        subFamilyData,
        packagingData,
        courierData,
        brandData,
        itemCategoryData,
        courierPartnerData,
      ] = await Promise.all([
        stockPromise,
        subFamilyPromise,
        PackagingPromise,
        courierModePromise,
        brandPromise,
        itemCategoryPromise,
        courierPartnerPromise,
      ]);
      setStockTypes(
        stockTypeData?.data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setSubFamilies(
        subFamilyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setPackagingData(
        packagingData.data.result.map((s) => ({
          ...s,
          label: s.title,
          value: s._id,
        }))
      );

      setCourierData(
        courierData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setBrandData(
        brandData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setCategory(
        itemCategoryData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setCourierPartner(
        courierPartnerData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    let dataToModify = formData[index];
    if (
      ["volumetric"].includes(name) &&
      dataToModify.volumetric &&
      dataToModify.courierMode
    ) {
      let divisionFactor = courierData.find(
        (c) => c._id == dataToModify.courierMode
      )?.divisionFactor;
      dataToModify = {
        ...dataToModify,
        [name]: value,
        volumetricWeight: multiplyStringNumbers(value) / divisionFactor,
      };
      return setFormData(
        formData.map((fd, i) => (i == index ? dataToModify : fd))
      );
    } else if (
      ["courierMode"].includes(name) &&
      dataToModify.volumetric &&
      dataToModify.courierMode
    ) {
      let divisionFactor = courierData.find(
        (c) => c._id == value
      )?.divisionFactor;
      dataToModify = {
        ...dataToModify,
        [name]: value,
        volumetricWeight:
          multiplyStringNumbers(dataToModify.volumetric) / divisionFactor,
      };
      return setFormData(
        formData.map((fd, i) => (i == index ? dataToModify : fd))
      );
    }
    if (name === "volumetric") {
      setFormData(
        formData.map((fd, i) => (i == index ? { ...fd, [name]: value } : fd))
      );
    } else {
      setFormData(
        formData.map((fd, i) => (i >= index ? { ...fd, [name]: value } : fd))
      );
    }
  };

  const getPickList = async (gmsPickListcode) => {
    try {
      const { data } = await get(URLS.pickList.list, {
        params: { search: { gmsPickListCode: gmsPickListcode } },
      });
      if (data.result[0]) {
        setPickLists(
          pickLists.filter((p) => p.value !== data.result[0]?.gmsPickListCode)
        );
        setSelectedPickLists([...selectedPickLists, data.result[0]?._id]);
        let newFormData = [];
        for (let item of [...data.result[0].individualItems]) {
          if (!formData.filter((f) => f._id === item._id).length) {
            setInStockItems(inStockItems.filter((i) => i.value !== item.msn));
            let newData = {
              _id: item._id,
              category: category.find((c) => c._id == item.itemCategory)?.name,
              msn: item.msn,
              bundleReferenceNumber: "NA",
              stockType: stockTypes.find((s) => s._id == item.stockType)?.name,
              packaging: "",
              courierMode: "",
              actualWeight: "",
              volumetric: "",
              volumetricWeight: "",
              value: "",
              dcNo: "",
              courierPartnerName: "",
              awbNo: "",
              ewayBill: "",
              pincode: "",

              subFamilyName: subFamilies.find((s) => s._id == item.subFamily)
                ?.name,
              itemDescription: item.itemDescription,
              itemCode: item.itemCode,
              manufacturerPartNumber: item.manufacturerPartNumber,
              brandName: brandData.find((b) => b._id == item.brandName)?.name,
              meta: formFields || [],
            };

            newFormData.push(newData);
          }
        }

        for (let item of [...data.result[0].bundledItems]) {
          setBundles(
            bundles.filter((i) => i.value !== item.bundleReferenceNumber)
          );

          let newData = {
            category: "Bundled",
            _id: item._id,
            items: item.items,
            msn: item.items.map((i) => i.msn).join(","),
            bundleReferenceNumber: item.bundleReferenceNumber,
            stockType: stockTypes.find((s) => s._id == item.stockType)?.name,
            packaging: "",
            courierMode: "",
            actualWeight: "",
            volumetric: "",
            volumetricWeight: "",
            value: "",
            dcNo: "",
            courierPartnerName: "",
            awbNo: "",
            ewayBill: "",
            pincode: "",
            subFamilyName: item.items
              .map((i) => subFamilies.find((s) => s._id == i.subFamily)?.name)
              .join(","),
            itemDescription: item.items.map((i) => i.itemDescription).join(","),
            itemCode: item.items.map((i) => i.itemCode).join(","),
            manufacturerPartNumber: item.items
              .map((i) => i.manufacturerPartNumber)
              .join(","),
            brandName: item.items
              .map((i) => brandData.find((b) => b._id == i.brandName)?.name)
              .join(","),
            meta: formFields || [],
          };

          newFormData.push(newData);
        }

        setFormData([...formData, ...newFormData]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getItem = async (msn) => {
    try {
      const { data } = await get(URLS.items.list, {
        params: { search: { msn, inStock: true, inBundle: false } },
      });
      let item = data.result[0];
      if (item) {
        setInStockItems(inStockItems.filter((i) => i.value !== item.msn));
        if (formData.filter((f) => f._id === item._id).length)
          return toast.info("This item has already been added");

        let newFormData = {
          _id: item._id,
          category: item.itemCategory.name,
          msn: item.msn,
          bundleReferenceNumber: "NA",
          stockType: item.stockType.name,
          packaging: "",
          courierMode: "",
          actualWeight: "",
          volumetric: "",
          volumetricWeight: "",
          value: "",
          dcNo: "",
          courierPartnerName: "",
          awbNo: "",
          ewayBill: "",
          pincode: "",
          subFamilyName: item.subFamily.name,
          itemDescription: item.itemDescription,
          itemCode: item.itemCode,
          manufacturerPartNumber: item.manufacturerPartNumber,
          brandName: item.brandName.name,
          meta: formFields || [],
        };

        setFormData([...formData, newFormData]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBundle = async (bundleReferenceNumber) => {
    try {
      const { data } = await get(URLS.bundle.list, {
        params: {
          search: {
            bundleReferenceNumber: bundleReferenceNumber,
            inStock: true,
          },
        },
      });
      let bundle = data.result[0];

      if (!bundle) return;

      setBundles(
        bundles.filter((b) => b.value !== bundle.bundleReferenceNumber)
      );

      if (formData.filter((f) => f._id === bundle._id).length)
        return toast.info("This bundle has already been added");

      let newFormData = {
        category: "Bundled",
        items: bundle.items,
        _id: bundle._id,
        msn: bundle.items.map((i) => i.msn).join(","),
        bundleReferenceNumber: bundle.bundleReferenceNumber,
        stockType: bundle.stockType.name,
        packaging: "",
        courierMode: "",
        actualWeight: "",
        volumetric: "",
        volumetricWeight: "",
        value: "",
        dcNo: "",
        courierPartnerName: "",
        awbNo: "",
        ewayBill: "",
        pincode: "",
        subFamilyName: bundle.items
          .map((i) => subFamilies.find((s) => s._id == i.subFamily._id)?.name)
          .join(","),
        itemDescription: bundle.items.map((i) => i.itemDescription).join(","),
        itemCode: bundle.items.map((i) => i.itemCode).join(","),
        manufacturerPartNumber: bundle.items
          .map((i) => i.manufacturerPartNumber)
          .join(","),
        brandName: bundle.items
          .map((i) => brandData.find((b) => b._id == i.brandName._id)?.name)
          .join(","),
        meta: formFields || [],
      };

      setFormData([...formData, newFormData]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formik.values.gmsPickListcode) {
      getPickList(formik.values.gmsPickListcode);
      formik.resetForm();
    }
  }, [formik.values.gmsPickListcode]);

  useEffect(() => {
    if (formik.values.manualBundleReferenceNumber) {
      getBundle(formik.values.manualBundleReferenceNumber);
      formik.resetForm();
    }
  }, [formik.values.manualBundleReferenceNumber]);

  useEffect(() => {
    if (formik.values.manualMsn) {
      getItem(formik.values.manualMsn);
      formik.resetForm();
    }
  }, [formik.values.manualMsn]);

  const handleRemove = (index) => {
    let itemOfGivenIndex = formData[index];
    if (itemOfGivenIndex.category === "Bundled") {
      setBundles([
        ...bundles,
        {
          label: itemOfGivenIndex.bundleReferenceNumber,
          value: itemOfGivenIndex.bundleReferenceNumber,
        },
      ]);
    } else {
      setInStockItems([
        ...inStockItems,
        { label: itemOfGivenIndex.msn, value: itemOfGivenIndex.msn },
      ]);
    }
    setFormData(formData.filter((v, ind) => index !== ind));
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    for (let fd of formData) {
      if (!hasAllValues(fd, ["meta", "volumetric", "volumetricWeight"])) return;
    }

    if (formData.some((fd) => fd.meta.some((f) => f.isRequired && !f.value)))
      return toast.error("Please fill required fields of this item");

    let outBounds = [];

    setIsLoading(true);
    for (let fd of formData) {
      if (outBounds.filter((o) => o.awbNo === fd.awbNo).length) {
        let itemToAdd = {
          packaging: fd.packaging,
          courierMode: fd.courierMode,
          actualWeight: fd.actualWeight,
          volumetricSize: fd.volumetric,
          volumetricWeight: fd.volumetricWeight,
          value: fd.value,
          dcNo: fd.dcNo,
          courierPartner: fd.courierPartnerName,
          awbNo: fd.awbNo,
          ewayBillNumber: fd.ewayBill,
          pincode: fd.pincode,
          meta: fd.meta,
        };

        let weightToAdd =
          Number(fd.volumetricWeight) > Number(fd.actualWeight)
            ? Number(fd.volumetricWeight)
            : Number(fd.actualWeight);

        if (fd.category === "Bundled") {
          itemToAdd["bundledItem"] = fd._id;
        } else {
          itemToAdd["individualItem"] = fd._id;
        }

        outBounds = outBounds.map((o) =>
          o.awbNo === fd.awbNo
            ? {
                ...o,
                totalWeight: o.totalWeight + weightToAdd,
                items: [...o.items, itemToAdd],
              }
            : o
        );
      } else {
        let itemToAdd = {
          packaging: fd.packaging,
          courierMode: fd.courierMode,
          actualWeight: fd.actualWeight,
          volumetricSize: fd.volumetric,
          volumetricWeight: fd.volumetricWeight,
          value: fd.value,
          dcNo: fd.dcNo,
          courierPartner: fd.courierPartnerName,
          awbNo: fd.awbNo,
          ewayBillNumber: fd.ewayBill,
          pincode: fd.pincode,
          meta: fd.meta,
        };
        if (fd.category === "Bundled") {
          itemToAdd["bundledItem"] = fd._id;
        } else {
          itemToAdd["individualItem"] = fd._id;
        }
        let newOutbound = {
          awbNo: fd.awbNo,
          totalWeight:
            Number(fd.volumetricWeight) > Number(fd.actualWeight)
              ? Number(fd.volumetricWeight)
              : Number(fd.actualWeight),
          items: [itemToAdd],
        };

        outBounds.push(newOutbound);
      }
    }

    try {
      const { data } = await post(URLS.outbound.create, {
        outBounds,
        warehouseId: selectedWarehouse,
        ownerCode: selectedOwnerCode,
      });
      setIsLoading(false);

      if (selectedPickLists.length) {
        await put(URLS.pickList.updateMultiple, { selectedPickLists });
      }
      setFormData([]);
      getAllPickList();
      getBundles();
      getItems();
      setSelectedPickLists([]);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const debounceSubmit = debounce(handleSubmit, 1000);

  // edit data actions
  const handleCustomInputChangeItem = (e, i) => {
    const { name, value } = e.target;
    setFormData(
      formData.map((f, index) =>
        index >= i
          ? {
              ...f,
              meta: f.meta.map((m) =>
                m.inputLabel === name ? { ...m, value } : m
              ),
            }
          : f
      )
    );
  };

  const handleFind = async (values) => {
    try {
      const { data } = await get(URLS.outbound.list, {
        params: {
          search: values,
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      let entries = data.result;
      if (entries && entries.length) {
        let forms = [];
        for (let entry of entries) {
          for (let item of entry.items) {
            if (item.individualItem) {
              let newForm = {
                _id: item.individualItem._id,
                category: item.individualItem.itemCategory.name,
                msn: item.individualItem.msn,
                bundleReferenceNumber: "NA",
                stockType: item.individualItem.stockType.name,
                packaging: item.packaging._id,
                courierMode: item.courierMode._id,
                actualWeight: item.actualWeight,
                volumetric: item.volumetricSize,
                volumetricWeight: item.volumetricWeight,
                value: item.value,
                dcNo: item.dcNo,
                courierPartnerName: item.courierPartner?._id,
                awbNo: item.awbNo,
                ewayBill: item.ewayBillNumber,
                pincode: item.pincode,
                subFamilyName: item.individualItem.subFamily.name,
                itemDescription: item.individualItem.itemDescription,
                itemCode: item.individualItem.itemCode,
                manufacturerPartNumber:
                  item.individualItem.manufacturerPartNumber,
                brandName: item.individualItem.brandName.name,
                meta: formFields?.map((f) => ({
                  ...f,
                  value:
                    item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                      ?.value || "",
                })),
              };
              forms.push(newForm);
            } else if (item.bundledItem) {
              let newForm = {
                category: "Bundled",
                _id: item.bundledItem._id,
                items: item.bundledItem.items,
                msn: item.bundledItem.items.map((i) => i.msn).join(","),
                bundleReferenceNumber: item.bundledItem.bundleReferenceNumber,
                stockType: stockTypes.find(
                  (s) => s._id == item.bundledItem.stockType
                )?.name,
                packaging: item.packaging?._id,
                courierMode: item.courierMode?._id,
                actualWeight: item.actualWeight,
                volumetric: item.volumetricSize,
                volumetricWeight: item.volumetricWeight,
                value: item.value,
                dcNo: item.dcNo,
                courierPartnerName: item.courierPartner._id,
                awbNo: item.awbNo,
                ewayBill: item.ewayBillNumber,
                pincode: item.pincode,
                subFamilyName: item.bundledItem.items
                  .map(
                    (i) =>
                      subFamilies.find((s) => s._id == i.subFamily._id)?.name
                  )
                  .join(","),
                itemDescription: item.bundledItem.items
                  .map((i) => i.itemDescription)
                  .join(","),
                itemCode: item.bundledItem.items
                  .map((i) => i.itemCode)
                  .join(","),
                manufacturerPartNumber: item.bundledItem.items
                  .map((i) => i.manufacturerPartNumber)
                  .join(","),
                brandName: item.bundledItem.items
                  .map(
                    (i) => brandData.find((b) => b._id == i.brandName._id)?.name
                  )
                  .join(","),
                meta: formFields?.map((f) => ({
                  ...f,
                  value:
                    item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                      ?.value || "",
                })),
              };

              forms.push(newForm);
            }
          }
        }
        setOutbounds(forms);
      } else {
        setOutbounds([]);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getAllEntries = async () => {
    try {
      const { data } = await get(URLS.outbound.list, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });

      let entries = data.result;

      if (entries && entries.length) {
        let forms = [];
        for (let entry of entries) {
          for (let item of entry.items) {
            if (item.individualItem) {
              let newForm = {
                _id: item.individualItem._id,
                category: item.individualItem.itemCategory.name,
                msn: item.individualItem.msn,
                bundleReferenceNumber: "NA",
                stockType: item.individualItem.stockType.name,
                packaging: item.packaging._id,
                courierMode: item.courierMode._id,
                actualWeight: item.actualWeight,
                volumetric: item.volumetricSize,
                volumetricWeight: item.volumetricWeight,
                value: item.value,
                dcNo: item.dcNo,
                courierPartnerName: item.courierPartner?._id,
                awbNo: item.awbNo,
                ewayBill: item.ewayBillNumber,
                pincode: item.pincode,
                subFamilyName: item.individualItem.subFamily.name,
                itemDescription: item.individualItem.itemDescription,
                itemCode: item.individualItem.itemCode,
                manufacturerPartNumber:
                  item.individualItem.manufacturerPartNumber,
                brandName: item.individualItem.brandName.name,
                meta: formFields?.map((f) => ({
                  ...f,
                  value:
                    item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                      ?.value || "",
                })),
              };
              forms.push(newForm);
            } else if (item.bundledItem) {
              let newForm = {
                category: "Bundled",
                _id: item.bundledItem._id,
                items: item.bundledItem.items,
                msn: item.bundledItem.items.map((i) => i.msn).join(","),
                bundleReferenceNumber: item.bundledItem.bundleReferenceNumber,
                stockType: stockTypes.find(
                  (s) => s._id == item.bundledItem.stockType
                )?.name,
                packaging: item.packaging?._id,
                courierMode: item.courierMode?._id,
                actualWeight: item.actualWeight,
                volumetric: item.volumetricSize,
                volumetricWeight: item.volumetricWeight,
                value: item.value,
                dcNo: item.dcNo,
                courierPartnerName: item.courierPartner._id,
                awbNo: item.awbNo,
                ewayBill: item.ewayBillNumber,
                pincode: item.pincode,
                subFamilyName: item.bundledItem.items
                  .map(
                    (i) =>
                      subFamilies.find((s) => s._id == i.subFamily._id)?.name
                  )
                  .join(","),
                itemDescription: item.bundledItem.items
                  .map((i) => i.itemDescription)
                  .join(","),
                itemCode: item.bundledItem.items
                  .map((i) => i.itemCode)
                  .join(","),
                manufacturerPartNumber: item.bundledItem.items
                  .map((i) => i.manufacturerPartNumber)
                  .join(","),
                brandName: item.bundledItem.items
                  .map(
                    (i) => brandData.find((b) => b._id == i.brandName._id)?.name
                  )
                  .join(","),
                meta: formFields?.map((f) => ({
                  ...f,
                  value:
                    item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                      ?.value || "",
                })),
              };

              forms.push(newForm);
            }
          }
        }
        setOutbounds(forms);
      } else {
        setOutbounds([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllEntries();
  }, [stockTypes, formFields, brandData, subFamilies, selectedWarehouse]);

  const entryFormik = useFormik({
    initialValues: {
      name: "",
      fromDate: dayjs(new Date()),
      toDate: dayjs(new Date()),
    },
    onSubmit: handleFind,
  });

  const handleClearFilter = () => {
    entryFormik.resetForm();
    getAllEntries();
  };

  return (
    <>
      <DashBoardPageHeaderPage title="Outbound" />
      <PageHeader
        module="Outbound"
        // title="Outbound"
        showAdd={false}
        collection="Outbound"
        showAddField={true}
        modelName="outbound"
        onFieldAdd={getFormDetails}
      />

      <TabList
        onChange={handleTabChange}
        value={value}
        labels={
          entryToEdit && value === 2
            ? ["Add", "Entries", "View"]
            : ["Add", "Entries"]
        }
      />

      <TabPanel index={0} value={value}>
        <Grid rowSpacing={1} columnSpacing={2} container>
          <Grid xs={12} sm={6} md={3} item>
            <FormSelect
              type="text"
              formik={formik}
              name="gmsPickListcode"
              label="GMS Picklist Code"
              required={true}
              options={pickLists}
            />
          </Grid>
          <Grid xs={12} sm={6} md={3} item>
            <FormSelect
              formik={formik}
              label="Bundle reference number"
              name="manualBundleReferenceNumber"
              options={bundles}
            />
          </Grid>
          <Grid xs={12} sm={6} md={3} item>
            <FormSelectVirtualized
              options={inStockItems}
              formik={formik}
              label="Item MSN"
              name="manualMsn"
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
            item
          >
            <Button
              sx={{}}
              variant="contained"
              size="small"
              onClick={refreshList}
            >
              Refresh
            </Button>
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ maxWidth: "80px", minWidth: "80px" }}
                  align="center"
                >
                  S.No
                </TableCell>
                <StyledTableCell align="center">MSN</StyledTableCell>
                <StyledTableCell align="center">
                  Bundle Reference No.
                </StyledTableCell>
                <StyledTableCell align="center">Stock Type</StyledTableCell>
                <StyledTableCell align="center">Packaging</StyledTableCell>
                <StyledTableCell align="center">Courier Mode</StyledTableCell>
                <StyledTableCell align="center">Actual Weight</StyledTableCell>
                <StyledTableCell align="center">
                  Volumetric( LXBXH)
                </StyledTableCell>
                <StyledTableCell align="center">
                  Volumetric Weight
                </StyledTableCell>
                <StyledTableCell align="center">Value</StyledTableCell>
                <StyledTableCell align="center">Dc No.</StyledTableCell>
                <StyledTableCell align="center">
                  Courier Partner Name
                </StyledTableCell>
                <StyledTableCell align="center">
                  Courier AWB No.
                </StyledTableCell>
                <StyledTableCell align="center">E Way Bill</StyledTableCell>
                <StyledTableCell align="center">Pincode</StyledTableCell>
                {formFields?.map((d, i) => (
                  <StyledTableCell key={i} align="center">
                    {d?.inputLabel}
                  </StyledTableCell>
                ))}
                <StyledTableCell align="center">Category</StyledTableCell>
                <StyledTableCell align="center">
                  Sub Family Name
                </StyledTableCell>
                <StyledTableCell align="center">
                  Item Description
                </StyledTableCell>
                <StyledTableCell align="center">Item Code</StyledTableCell>
                <StyledTableCell align="center">
                  Manufacturer Part No.
                </StyledTableCell>
                <StyledTableCell align="center">Brand Name</StyledTableCell>

                {/* <StyledTableCell
                  sx={{ maxWidth: "100px", minWidth: "100px" }}
                  align="center"
                >
                  Remove
                </StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {formData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((fd, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{ maxWidth: "80px", minWidth: "80px" }}
                      align="center"
                    >
                      {index + 1}
                    </TableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.msn}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.bundleReferenceNumber}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.stockType}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        name="packaging"
                        value={fd.packaging}
                        label="Packaging"
                        options={packagingData}
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        name="courierMode"
                        options={courierData}
                        value={fd.courierMode}
                        label="Courier Mode"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        name="actualWeight"
                        value={fd.actualWeight}
                        label="Actual Weight"
                        required={true}
                        type="number"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        name="volumetric"
                        value={fd.volumetric}
                        label="Volumetric"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        name="volumetricWeight"
                        value={fd.volumetricWeight}
                        label="Volumetric Weight"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        name="value"
                        value={fd.value}
                        label="Value"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        name="dcNo"
                        value={fd.dcNo}
                        label="Dc No."
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        name="courierPartnerName"
                        options={courierPartner}
                        value={fd.courierPartnerName}
                        label="Courier Partner Name"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        name="awbNo"
                        value={fd.awbNo}
                        label="Courier AWB No."
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        name="ewayBill"
                        value={fd.ewayBill}
                        label="E Way Bill"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        name="pincode"
                        value={fd.pincode}
                        label="Pincode"
                        required={true}
                        type="number"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    {formFields
                      ? fd?.meta?.map((f, i) => (
                          <StyledTableCell align="center" key={i}>
                            <CustomInput
                              onChange={(e) =>
                                handleCustomInputChangeItem(e, index)
                              }
                              name={f.inputLabel}
                              value={f.value}
                              required={f.isRequired}
                              label={f.inputLabel}
                              type={f.inputType}
                            />
                          </StyledTableCell>
                        ))
                      : null}
                    <StyledTableCell align="center">
                      <Typography>{fd.category}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.subFamilyName}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.itemDescription}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.itemCode}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.manufacturerPartNumber}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.brandName}</Typography>
                    </StyledTableCell>

                    {/* <StyledTableCell
                    sx={{ maxWidth: "100px", minWidth: "100px" }}
                    align="center"
                  >
                    <ShowComponent module={"Outbound"} action={"delete"}>
                      {" "}
                      <IconButton
                        onClick={() => handleRemove(index)}
                        size="small"
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </ShowComponent>
                  </StyledTableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {formData.length ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 2,
            }}
          >
            <LoadingButton
              loading={isLoading}
              onClick={debounceSubmit}
              size="small"
              variant="contained"
            >
              Submit
            </LoadingButton>
          </Box>
        ) : null}
      </TabPanel>

      <TabPanel index={1} value={value}>
        <Grid rowSpacing={1} columnSpacing={2} container>
          <Grid xs={12} sm={6} md={3} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="name"
              label="AWB No"
              required={true}
            />
          </Grid>

          <Grid xs={12} sm={6} md={3} item>
            <FormDatePicker
              formik={entryFormik}
              label="From Date"
              name="fromDate"
            />
          </Grid>
          <Grid xs={12} sm={6} md={3} item>
            <FormDatePicker
              formik={entryFormik}
              label="To Date"
              name="toDate"
            />
          </Grid>
          <Grid xs={12} sm={6} md={3} item>
            <Stack columnGap={1} direction="row">
              <Button
                size="small"
                onClick={entryFormik.handleSubmit}
                variant="contained"
                sx={{ mt: 2 }}
              >
                Filter
              </Button>
              {entryFormik.values.name ? (
                <Button
                  size="small"
                  onClick={handleClearFilter}
                  variant="outlined"
                  sx={{ mt: 2 }}
                >
                  Clear Filter
                </Button>
              ) : null}
            </Stack>
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ maxWidth: "80px", minWidth: "80px" }}
                  align="center"
                >
                  S.No
                </TableCell>
                <StyledTableCell align="center">MSN</StyledTableCell>
                <StyledTableCell align="center">
                  Bundle Reference No.
                </StyledTableCell>
                <StyledTableCell align="center">Stock Type</StyledTableCell>
                <StyledTableCell align="center">Packaging</StyledTableCell>
                <StyledTableCell align="center">Courier Mode</StyledTableCell>
                <StyledTableCell align="center">Actual Weight</StyledTableCell>
                <StyledTableCell align="center">
                  Volumetric( LXBXH)
                </StyledTableCell>
                <StyledTableCell align="center">
                  Volumetric Weight
                </StyledTableCell>
                <StyledTableCell align="center">Value</StyledTableCell>
                <StyledTableCell align="center">Dc No.</StyledTableCell>
                <StyledTableCell align="center">
                  Courier Partner Name
                </StyledTableCell>
                <StyledTableCell align="center">
                  Courier AWB No.
                </StyledTableCell>
                <StyledTableCell align="center">E Way Bill</StyledTableCell>
                <StyledTableCell align="center">Pincode</StyledTableCell>
                {formFields?.map((d, i) => (
                  <StyledTableCell key={i} align="center">
                    {d?.inputLabel}
                  </StyledTableCell>
                ))}
                <StyledTableCell align="center">Category</StyledTableCell>
                <StyledTableCell align="center">
                  Sub Family Name
                </StyledTableCell>
                <StyledTableCell align="center">
                  Item Description
                </StyledTableCell>
                <StyledTableCell align="center">Item Code</StyledTableCell>
                <StyledTableCell align="center">
                  Manufacturer Part No.
                </StyledTableCell>
                <StyledTableCell align="center">Brand Name</StyledTableCell>

                {/* <StyledTableCell
                  sx={{ maxWidth: "100px", minWidth: "100px" }}
                  align="center"
                >
                  Remove
                </StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {outbounds
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((fd, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{ maxWidth: "80px", minWidth: "80px" }}
                      align="center"
                    >
                      {index + 1}
                    </TableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.msn}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.bundleReferenceNumber}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.stockType}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        disabled={true}
                        name="packaging"
                        value={fd.packaging}
                        label="Packaging"
                        options={packagingData}
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        disabled={true}
                        name="courierMode"
                        options={courierData}
                        value={fd.courierMode}
                        label="Courier Mode"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        disabled={true}
                        name="actualWeight"
                        value={fd.actualWeight}
                        label="Actual Weight"
                        required={true}
                        type="number"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        disabled={true}
                        name="volumetric"
                        value={fd.volumetric}
                        label="Volumetric"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        disabled={true}
                        name="volumetricWeight"
                        value={fd.volumetricWeight}
                        label="Volumetric Weight"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        disabled={true}
                        name="value"
                        value={fd.value}
                        label="Value"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        disabled={true}
                        name="dcNo"
                        value={fd.dcNo}
                        label="Dc No."
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        disabled={true}
                        name="courierPartnerName"
                        options={courierPartner}
                        value={fd.courierPartnerName}
                        label="Courier Partner Name"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        disabled={true}
                        name="awbNo"
                        value={fd.awbNo}
                        label="Courier AWB No."
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        disabled={true}
                        name="ewayBill"
                        value={fd.ewayBill}
                        label="E Way Bill"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        disabled={true}
                        name="pincode"
                        value={fd.pincode}
                        label="Pincode"
                        required={true}
                        type="number"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    {formFields
                      ? fd?.meta?.map((f, i) => (
                          <StyledTableCell align="center" key={i}>
                            <CustomInput
                              onChange={(e) =>
                                handleCustomInputChangeItem(e, index)
                              }
                              disabled={true}
                              name={f.inputLabel}
                              value={f.value}
                              required={f.isRequired}
                              label={f.inputLabel}
                              type={f.inputType}
                            />
                          </StyledTableCell>
                        ))
                      : null}
                    <StyledTableCell align="center">
                      <Typography>{fd.category}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.subFamilyName}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.itemDescription}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.itemCode}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.manufacturerPartNumber}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.brandName}</Typography>
                    </StyledTableCell>

                    {/* <StyledTableCell
                    sx={{ maxWidth: "100px", minWidth: "100px" }}
                    align="center"
                  >
                    <ShowComponent module={"Outbound"} action={"delete"}>
                      {" "}
                      <IconButton
                        disabled={true}
                        onClick={() => handleRemove(index)}
                        size="small"
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </ShowComponent>
                  </StyledTableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={outbounds ? (outbounds.length ? outbounds.length : 0) : 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 20]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TabPanel>
    </>
  );
}
